import React from 'react'
import "../Styles/Accessories.scss"
import {ReactComponent as PenyesaLogo} from "../Images/PenyesaLogoLight.svg"
import Lady from "../Images/homeLady.png"

const Accessories = () => {
    return (
        <div className="accessories">
            <div className="accessories-header">
                <PenyesaLogo width="8rem" fill="white"/>
            </div>
            <div className="accessories-body">
                
                <div className="cta cta-accessories">
                    <a className="link" href="https://accessories.penyesa.co.za">
                        <button className="cta-btn cta-btn-secondary">
                            Accessories
                        </button>
                    </a>
                    <h1 className="cta-text">
                        Shop for a wide variety of ladies African inspired fashion items including accessories,footwear,clothing & many more
                    </h1>
                </div>
                <img className="accessories-image" src={Lady} alt= "Penyesa Accessories Lady"/>
            </div>
        </div>
    )
}

export default Accessories
