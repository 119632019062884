import React from 'react';
import Landing from "./Components/Landing"
import Footer from "./Components/Footer"
import {Route, Switch} from "react-router-dom"

function App() {

  const AppStyle = {
    width : "100%",
    height : "100%",
    display: "flex",
    flexDirection : 'column',
    position : "relative"
  }
  return (
    <Switch>
      <Route exact path="/" render={(routeProps) => 
          <div style={AppStyle}>
            <Landing/>
            <Footer/> 
          </div>}
        />
    </Switch>
  );
}

export default App;
