import React,{Suspense,lazy} from 'react'
import "../Styles/landing.scss"
import Pantry from './Pantry'
import Accessories from "./Accessories"
import Info from "./Info"
import {useMediaQuery} from "react-responsive"

const Mobile = lazy(() => import("./Mobile"))
const Landing = () => {

    const isMobile = useMediaQuery({
        query : "(max-width : 580px)"
    })
    return (
        
            !isMobile ? (
                <div className="container">
                    <Pantry/>
                    <Accessories/>
                    <Info/>
                </div>
            ) : (
                <Suspense fallback={<div>Mobile Loading...</div>}>
                    <Mobile/>
                </Suspense>
            )
        
        
    )
}

export default Landing
