import React from 'react'
import {ReactComponent as Order} from "../Images/serviceOrder.svg";
import {ReactComponent as Pay} from "../Images/servicePay.svg";
import {ReactComponent as Deliver} from "../Images/serviceDeliver.svg";
const Info = () => {
    return (
        <div className="pantry-info">
                <div className ="service">
                    <h1 className="service-header">3 Simple Steps</h1>
                    <div className="service-label">
                        <div className="service-text">
                            Order
                        </div>
                        <Order className="service-icon" fill="#5b4e03"/>
                    </div>
                    <div className="service-label">
                        <div className="service-text">
                            Pay
                        </div>
                        <Pay className="service-icon" fill="#5b4e03"/>
                    </div>
                    <div className="service-label">
                        <div className="service-text">
                            We Deliver
                        </div>
                        <Deliver className="service-icon" fill="#5b4e03"/>
                       
                    </div>
                </div>
            </div>
    )
}

export default Info
