import React from 'react'
import "../Styles/Pantry.scss"
import {ReactComponent as PenyesaLogo} from "../Images/PenyesaLogo.svg"
import TrolleyBig from "../Images/trolleyBig.png"


function Pantry() {
    return (
        <div className="pantry">
            <div className="pantry-header">
                <PenyesaLogo width="8rem"/>
            </div>
            <div className="pantry-body">
                <img className="pantry-image" src={TrolleyBig} alt= "Penyesa Shopping Trolley"/>
                <div className="cta cta-pantry">
                    
                    <h1 className="cta-text" style={{"margin": "0 0 2rem 0"}}>
                        Shop & pay online for groceries from wherever you are, to be delivered to any doorstep in Zimbabwe
                    </h1>
                    <a href="https://www.pantry.penyesa.co.za">
                        <button className="cta-btn cta-btn-main">
                            Pantry & Home
                        </button>
                    </a>
                </div>
            </div>
            
        </div>
    )
}

export default Pantry
